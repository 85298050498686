import styled from "styled-components";

export const PageWrapper = styled.section`
  background-color: #e5e5e5;
  padding-bottom: 117px;
  @media only screen and (max-width: 1400px) {
    padding-bottom: 46px;
  }
  .t1 {
    max-width: 230px;
    margin: 0 auto;
    padding: 96px 0 83px;
    @media only screen and (max-width: 1400px) {
      width: 150px;
      padding: 41px 0 70px;
    }
  }
  .blockContainer {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 1400px) {
      padding: 0 32px;
    }
    @media only screen and (max-width: 800px) {
      flex-direction: column;
      padding: 0;
    }
    .b1 {
      background: #f8f9fa;
      margin-right: 32px;
      background-image: url(../../img/bgBlock1.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media only screen and (max-width: 800px) {
        margin-right: 24px;
      }
      @media only screen and (max-width: 800px) {
        margin-right: 0;
      }
      h2 {
        color: #2f3137;
        max-width: 400px;
      }
      p {
        color: #939598;
        max-width: 500px;
      }
    }
    .b2 {
      background: #2f3137;
      h2 {
        color: #ffff;
      }
      p {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .t2 {
    width: 440px;
    margin: 0 auto;
    padding: 96px 0 42px;
    @media only screen and (max-width: 1400px) {
      width: 250px;
      padding: 41px 0 26px;
      font-size: 32px;
      line-height: 39px;
    }
  }
  .subTitle {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: rgba(47, 49, 55, 0.5);
    @media only screen and (max-width: 1400px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const AccordionWrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #e5e5e5;

  .conteinerAccordion {
    width: 928px;
    margin: 0 auto;
    padding: 86px 72px 120px;
    @media only screen and (max-width: 800px) {
      padding: 41px 28px 26px;
    }
    .containerBtn {
      display: grid;
      grid-template-columns: 2fr 6fr;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-bottom: 21px;
        border-bottom: solid 2px rgba(73, 73, 73, 0.2);
        text-align: center;
        transition: 0.2s;
        margin-bottom: 40px;
        align-items: center;
        svg {
          width: 32px;
          height: 32px;
          margin-right: 16px;
          background: rgba(73, 73, 73, 0.2);
          border-radius: 5px;
          padding: 7px;
          transition: 0.2s;
          @media only screen and (max-width: 800px) {
            margin-right: 8px;
          }
        }
        p {
          transition: 0.2s;
          font-size: 20px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #2f3137;
          align-items: center;
          display: flex;
          @media only screen and (max-width: 800px) {
            font-size: 14px;
            line-height: 159.52%;
            max-width: 156px;
            height: 44px;
          }
        }
        .p1 {
          @media only screen and (max-width: 800px) {
            padding-top: 10px;
          }
        }
      }
      .active {
        border-bottom: solid 2px #69cf67;
        svg {
          color: #69cf67;
          background: rgba(105, 207, 103, 0.2);
        }
        p {
          font-weight: bold;
          color: #69cf67;
        }
      }
      .item2 {
        justify-content: center;
      }
    }
  }
`;
