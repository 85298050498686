import React, { useState } from "react";
import Panel from "./PanelTable";

import FeatherIcon from "feather-icons-react";

const AccordionTable = (data) => {
  const [table, setTable] = useState(1);
  const [activeTab, setActiveTab] = useState(0);

  function ActiveTab(index) {
    setActiveTab(activeTab === index ? -1 : index);
  }

  return (
    <div className="conteinerAccordion">
      {table === 1 ? (
        <div className="containerBtn">
          <a onClick={() => setTable(1)} className="active">
            <FeatherIcon icon="activity" />
            <p className="p1">exames</p>
          </a>
          <a onClick={() => setTable(2)} className="item2">
            <FeatherIcon icon="activity" />
            <p>exames com agendamento</p>
          </a>
        </div>
      ) : (
        <div className="containerBtn">
          <a onClick={() => setTable(1)}>
            <FeatherIcon icon="activity" />
            <p>exames</p>
          </a>
          <a onClick={() => setTable(2)} className="item2 active">
            <FeatherIcon icon="activity" />
            <p>exames com agendamento</p>
          </a>
        </div>
      )}

      {table === 1 ? (
        <div className="accordion" role="tablist">
          {data.props.data.markdownRemark.frontmatter.exames.map((panel, index) => (
            <Panel
              key={index}
              index={index}
              {...panel}
              activateTab={() => ActiveTab(index)}
              activeTab={activeTab}
            />
          ))}
        </div>
      ) : (
        <div className="accordion" role="tablist">
          {data.props.data.markdownRemark.frontmatter.examesComAgendamento.map((panel, index) => (
            <Panel
              key={index}
              activeTab={activeTab}
              index={index}
              {...panel}
              activateTab={() => ActiveTab(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AccordionTable;
