import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import AccordionTable from "../components/AccordionTable";
import * as S from "../styles/servicePage/styles";

const ServicePage = (data) => {
  return (
    <Layout>
      <S.PageWrapper>
        <div className="title t1">
          <h2>Serviços</h2>
          <div className="border"></div>
        </div>
        <div className="blockContainer">
          <div className="block1 b1">
            <h2>Já fez exames conosco?</h2>
            <p>Clique e veja agora mesmo o resultado dos seus exames!</p>
            
            <Link                   
                to="/modal"
                state={{
                  modal: true,
                }}>Ver resultado dos exames</Link>
          </div>
          <div className="block1 b2">
            <h2>Precisa agendar um exame?</h2>
            <p>Clique e agende com um de nossos atendentes agora mesmo!</p>
            <a href="https://api.whatsapp.com/send?phone=5562984147797">Quero agendar meu exame agora</a>
          </div>
        </div>
        <div className="title t2">
          <h2>Lista de exames</h2>
          <div className="border"></div>
        </div>
        <p className="subTitle">
          Confira abaixo a lista de exames disponíveis e seus respectivos
          preparatórios:
        </p>
        <S.AccordionWrapper>
          <AccordionTable props={data}/>
        </S.AccordionWrapper>
        <div className="block2 bk2">
          <div>
            <h2>Não encontrou o exame que você necessita?</h2>
          </div>
          <div className="block2Btn">
            <Link to="/contact">Fale conosco</Link>
          </div>
        </div>
      </S.PageWrapper>
    </Layout>
  );
};

const Services = ({ data }) => {
  const { markdownRemark: servicos } = data;
  return (
    <Layout>
      <ServicePage text={servicos.frontmatter.text} />
    </Layout>
  );
};
Services.propTypes = {
  data: PropTypes.object.isRequired,
};
export default ServicePage;

export const pageQuery = graphql`
  query servicePage {
    markdownRemark(frontmatter: { templateKey: { eq: "servicePage" } }) {
      frontmatter {
        exames {
          label
          content
        }
        examesComAgendamento {
          label
          content
        }
      }
    }
  }
`;